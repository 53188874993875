import { Container } from '@mui/material'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'
import { addApolloState, initializeApollo } from '../lib/apolloClient'
import NotFound from '../src/components/Modules/NotFound'
import Layout from '../src/constants/Layout'
import { AppContextWrapper } from '../src/context/appContext'
import {
  GET_PARENT_CATEGORIES,
  GET_PARENT_CATEGORIES_NON_RECURSIVE,
} from '../src/operations/queries'
import { APP_SHOP_SETTINGS } from '../src/operations/queries/_appQueries'
import { sortCategories } from '../src/utilityFunctions/categoryUtils'

const FourOFourPage = ({
  locale,
  _nextI18Next,
  shopSettingsData,
  categoriesData,
  ...props
}): JSX.Element => {
  const shopSettings = { ...shopSettingsData.data.findFirstShopSetting }

  return (
    <AppContextWrapper locale={locale} shopSettings={shopSettings}>
      <Layout categoriesData={categoriesData} locale={locale}>
        <Container maxWidth="xl">
          <NotFound />
        </Container>
      </Layout>
    </AppContextWrapper>
  )
}

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  locales,
  defaultLocale,
}) => {
  const apolloClient = initializeApollo()
  const SHOP_ID = JSON.parse(process.env.NEXT_PUBLIC_SHOP_ID)

  const shopSettingsData = await apolloClient.query({
    query: APP_SHOP_SETTINGS,
    variables: {
      SHOP_ID,
      isHomepage: false,
      isProductPage: false,
    },
  })

  // --- CATEGORY =============
  // const categoriesData = await apolloClient.query({
  //   query: GET_PARENT_CATEGORIES,
  //   variables: {
  //     language: locale,
  //   },
  // })
  const categoriesDataQuery = await apolloClient.query({
    query: GET_PARENT_CATEGORIES_NON_RECURSIVE,
    variables: {
      language: locale,
    },
  })
  let allCategories = [
    ...JSON.parse(JSON.stringify(categoriesDataQuery.data.findManyCategory)),
  ]
  const sortedByMenuOrder = allCategories.sort(
    (a, b) => a.menu_order - b.menu_order,
  )
  const sortedCategories = sortCategories(sortedByMenuOrder)
  // const flatCategories = flattenCategories(sortedCategories)
  let categoriesData = { ...JSON.parse(JSON.stringify(categoriesDataQuery)) }
  categoriesData.data.findManyCategory = sortedCategories
  // ---------------- END  CATEGORY =============

  return addApolloState(apolloClient, {
    props: {
      locale,
      locales,
      defaultLocale,
      categoriesData,
      ...(await serverSideTranslations(locale, ['common'])),
      shopSettingsData,
    },
    revalidate: 5,
  })
}

export default FourOFourPage
