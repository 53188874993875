import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import Link from 'next/link'
import React from 'react'

const NotFound = (): JSX.Element => {
  const theme = useTheme()

  return (
    <>
      <Box
        position={'relative'}
        minHeight={'calc(100vh - 247px)'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
              xs={12}
              md={6}
            >
              <Box>
                <Typography
                  variant="h1"
                  component={'h1'}
                  align={'center'}

                  // align={isMd ? 'left' : 'center'}
                  // sx={{ fontWeight: 700 }}
                >
                  404
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  // align={isMd ? 'left' : 'center'}
                  align={'center'}
                >
                  Oops! Looks like you followed a bad link.
                  <br />
                  If you think this is a problem with us, please{' '}
                  <Link prefetch={false} href={''}>
                    tell us
                  </Link>
                </Typography>
                <Box
                  marginTop={4}
                  display={'flex'}
                  justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                  <Button
                    // component={a}
                    variant="contained"
                    color="secondary"
                    size="large"
                    href={'/'}
                  >
                    Back home
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} md={6}>
              <Box height={1} width={1} maxWidth={500}>
                <img
                  src={'/404-picture.svg'}
                  // width={1}
                  // height={1}
                  // sx={{
                  //   filter:
                  //     theme.palette.mode === 'dark'
                  //       ? 'brightness(0.8)'
                  //       : 'none',
                  // }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default NotFound
